import { showPage, defaultRoute } from './base';
import app from 'lib@/app';
import LayoutRpmCpn from 'cpn@/layout_rpm_cpn';
import { load_lang,set_current_lang,lang } from 'lib@/lang';
import enus from 'src@/lang/en-us.json';
import esla from 'src@/lang/es-la.json';
import User from "lib@/user";

/**
 * 呼吸部落pro 海外版 顾问端web入口 rpm.huxihome.com
 */
let langName=User.getUserLang();
set_current_lang(langName)
let form = layui.form;
if(langName=='en-us'){
    load_lang('en-us', enus);
}else if(langName=='es-la'){
    load_lang('es-la', esla);
}

form.config.verify.required[1] = lang.required_msg;
form.config.verify.phone[1]    = lang.phone_msg;
form.config.verify.email[1]    = lang.email_msg;
form.config.verify.number[1]   = lang.number_msg;
form.config.verify.date[1]     = lang.date_msg;

app.entryPoint = 'rpm';
app.setDefaultLayoutCpn(new LayoutRpmCpn({
    App      : app,
    auto_init: false,
}));

let router = app.router;

router.route(
    '/login',
    (req, res, next) => {
        App.req  = req;
        App.res  = res;
        App.next = next;

        App.getPage('ext/rpm/login').then((page) => {
            showPage(page);
        });
    }
);

router.route(
    '/register',
    (req, res, next) => {
        App.req  = req;
        App.res  = res;
        App.next = next;

        App.getPage('ext/rpm/register').then((page) => {
            showPage(page);
        });
    }
);

router.route(
    '*',
    (req, res, next) => {
        defaultRoute(req, res, next);
    }
);