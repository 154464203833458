/**
 * 工具函数
 */

/**
 * 打印日志的函数 正式环境下不打印
 * @param {any} data 
 */
export function log(data) {
    if(process.env.NODE_ENV != 'production'){
        console.log(data);
    }
}

/**
 * 判断参数是否为空对象的方法
 * @param {Object} obj 
 * @returns boolean
 */
export function isEmptyObj(obj){
    return (obj && Object.keys(obj).length > 0) ? false : true;
}

/**
 * 判断嵌套的属性，是否存在的函数
 * @param {object} obj          待判断的对象
 * @param {string} propertyPath 嵌套的属性链
 * @returns boolean
 */
export function checkObjProperty (obj, propertyPath) {
    if(!obj || obj == 'undefined' || obj == undefined){
        return false;
    }
    let arr = propertyPath.split(".");
    let tempObj = obj;
    for(let i = 0; i < arr.length; i++)
    {
        if(tempObj[arr[i]] == undefined){
            return false;
        }else{
            tempObj = tempObj[arr[i]];
        }
    }

    return true;
}

/**
 * 获取对象属性的值的函数
 * @param {object} obj          待判断的对象
 * @param {string} propertyPath 嵌套的属性链
 * @param {any}    defaultVal   属性不存在时的返回值
 * @returns any
 */
export function getObjProperty(obj, propertyPath, defaultVal = undefined) {
    if(checkObjProperty(obj, propertyPath)){
        let arr = propertyPath.split(".");
        let tempObj = obj;
        for(let i = 0; i < arr.length; i++){
            tempObj = tempObj[arr[i]];
        }
        return tempObj;
    }
    else{
        return defaultVal;
    }
}

/**
 * 判断变量是否为空值的方法
 * @param {any} val 
 * @returns boolean
 */
export function hasValue(val) {
    return (val != null && val != undefined && val != '') ? true : false;
}

/**
 * 将对象中，不为空值的属性，进行 url encoe 编码的函数
 * @param {object} obj 
 * @returns {string}
 */
export function urlEncode(obj) {
    let params = [];

    for(let k in obj){
        if(hasValue(obj[k])){
            params.push(`${k}=${encodeURIComponent(obj[k])}`);
        }
    }

    return params.join('&');
}

/**
 * 获取原始dom的
 * @param {string}} id 
 * @returns dom
 */
export function getDom(id) {
    return document.getElementById(id);
}

/**
 * 对原始值进行指定位数的四舍五入的函数
 * @param {number} val 原始值
 * @param {integer} bit 希望保留的小数位数
 * @returns 相应精度的值
 */
export function myRound(val, bit){
    let t = Math.pow(10, bit);
    return Math.round(val * t) / t;
}